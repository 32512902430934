// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import classnames from 'classnames';

import RichText from '~plugins/prismic/components/RichText';

import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';

import type { Props } from './types';
import styles from './styles';

const TextSlice = ({
  data,
  component,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      <RichText {...data?.primary?.slice_text} className={classes.text} />
    </Component>
  );
};

TextSlice.defaultProps = {
  className: undefined,
};

export const StyledTextSlice = withStyles<*, *, Props>(styles, { name: 'TextSlice' })(TextSlice);
export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledTextSlice>,
>({ paddingFactor: [2.5, 0] })(StyledTextSlice);
