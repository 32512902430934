// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import classnames from 'classnames';

import RichText from '~plugins/prismic/components/RichText';
import NodeImage from '~plugins/prismic/components/NodeImage';

import withBodySectionSlicePattern from '~hocs/withBodySectionSlicePattern';

import type { Props } from './types';
import styles from './styles';

const QuoteSlice = ({
  data,
  component,
  ContainerProps,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const Component = component || Container;

  const hasImage = data?.primary?.slice_image?.fluid != null;

  return (
    <Component className={classnames(classes.root, className)} {...props}>
      {data?.primary?.slice_title ? (
        <RichText {...data?.primary?.slice_title} className={classes.title} />
      ) : null}
      <Container
        maxWidth="md"
        className={classnames(classes.quoteContainer, {
          [classes.quoteContainerWithImage]: hasImage,
        })}
      >
        {hasImage && (
          <div className={classes.quoteImageWrapper}>
            <NodeImage
              data={data.primary?.slice_image}
              className={classes.sliceImage}
            />
          </div>
        )}
        <div className={classes.quoteWrapper}>
          <FormatQuoteIcon fontSize="large" className={classes.formatQuote} />
          <div>
            <RichText
              {...data?.primary?.slice_quote}
              className={classes.sliceQuote}
            />
            <RichText
              {...data?.primary?.slice_quote_name}
              className={classes.sliceQuoteName}
            />
          </div>
        </div>
      </Container>
    </Component>
  );
};

QuoteSlice.defaultProps = {
  className: undefined,
};

export const StyledQuoteSlice = withStyles<*, *, Props>(styles, {
  name: 'QuoteSlice',
})(QuoteSlice);

export default withBodySectionSlicePattern<
  React.ElementConfig<typeof StyledQuoteSlice>,
>({ paddingFactor: [2, 0] })(StyledQuoteSlice);
