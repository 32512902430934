// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  title: {
    marginBottom: theme.spacing(2),
    '& b, & strong': {
      color: theme.palette.primary.main,
    },
  },
  quoteContainer: {},
  quoteContainerWithImage: {
    display: 'flex',
    alignItems: 'center',
    gap: `${theme.spacing(4)}px`,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gap: `${theme.spacing(2.5)}px`,
    },
  },
  quoteImageWrapper: {},
  quoteWrapper: { display: 'flex' },
  formatQuote: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(3),
  },
  sliceImage: {
    width: 200,
    height: 'auto',
  },
  sliceQuote: {
    textAlign: 'left',
  },
  sliceQuoteName: {
    paddingTop: theme.spacing(4),
    textAlign: 'left',
  },
});

export default styles;
