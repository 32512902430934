// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';

import NodeBody from '~plugins/prismic/components/NodeBody';
import useGroupedSlicesByType from '~plugins/prismic/hooks/useGroupedSlicesByType';
import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import ComplexHeader from '~components/ComplexHeader';
import ItemWithIcon from '~components/ItemWithIcon';
import TextSlice from '~components/slices/TextSlice';
import QuoteSlice from '~components/slices/QuoteSlice';
import FooterContactForm from '~components/FooterContactForm';
import type { PrismicUseCase } from '~schema';
import useAllNodeBodyComponents from '~hooks/useAllNodeBodyComponents';

import { SLICE_TABBED_CARD_GROUPED_NAME } from '../constants';

export type ClassKey =
  | 'root'
  | 'header'
  | 'additionalInfo'
  | 'additionalInfoGridContainer'
  | 'additionalInfoGridItem'
  | 'bodySection';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicUseCase>>,
};

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  header: {
    textAlign: 'center',
  },
  additionalInfo: {},
  additionalInfoGridContainer: {
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(3),
    },
  },
  additionalInfoGridItem: {},
  bodySection: {},
});

const { download_form, ...allNodeBodyComponents } = useAllNodeBodyComponents();

const nodeBodyComponents = {
  text: TextSlice,
  quote: QuoteSlice,
  ...allNodeBodyComponents,
};

const UseCasePage = ({ classes, node }: Props) => {
  const theme = useTheme<Theme>();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const body = useGroupedSlicesByType({
    slices: node?.data?.body,
    sliceType: 'tabbed_cards',
    groupedType: SLICE_TABBED_CARD_GROUPED_NAME,
  });

  return (
    <div className={classes.root}>
      <ComplexHeader
        title={node?.data?.title}
        subtitle={node?.data?.subtitle}
        useCaseLogo={node?.data?.use_case_logo}
        backgroundImage={node?.data?.background_image}
        tags={node?.data?.tags}
        containerMaxWidth="md"
        classes={{ root: classes.header }}
      />
      <Container className={classes.additionalInfo}>
        <Grid
          container
          spacing={isSmUp ? 6 : 3}
          className={classes.additionalInfoGridContainer}
        >
          <Grid
            item
            xs={12}
            sm="auto"
            className={classes.additionalInfoGridItem}
          >
            <ItemWithIcon
              icon={node?.data?.client_icon}
              title={node?.data?.client_label?.text}
              description={node?.data?.client_text}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm="auto"
            className={classes.additionalInfoGridItem}
          >
            <ItemWithIcon
              icon={node?.data?.date_icon}
              title={node?.data?.date_label?.text}
              description={node?.data?.date_text}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm="auto"
            className={classes.additionalInfoGridItem}
          >
            <ItemWithIcon
              icon={node?.data?.location_icon}
              title={node?.data?.location_label?.text}
              description={node?.data?.location_text}
            />
          </Grid>
        </Grid>
      </Container>
      <NodeBody
        node={node}
        body={body}
        components={nodeBodyComponents}
        className={classes.bodySection}
      />
      <FooterContactForm currentPage={node?.data?.name?.text} />
    </div>
  );
};

export default compose(
  withNodePage<PrismicUseCase, *>({
    getNode: data => data?.prismicUseCase,
  }),
  withStyles<ClassKey, *, Props>(styles, { name: 'UseCasePage' }),
)(UseCasePage);

export const query = graphql`
  query UseCasePageQuery($prismicId: ID) {
    prismicUseCase(prismicId: { eq: $prismicId }) {
      id
      lang
      uid
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        name {
          text
        }
        title {
          text
          html
        }
        subtitle {
          text
          html
        }
        tags {
          tag {
            document {
              ... on PrismicEntryTag {
                id
                uid
                data {
                  name {
                    text
                    html
                  }
                }
              }
            }
          }
        }
        background_image {
          alt
          fluid(maxWidth: 1200) {
            ...GatsbyPrismicImageFluid
          }
        }
        use_case_logo {
          alt
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 300, quality: 85) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
        client_icon {
          alt
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 50, quality: 85) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
        client_label {
          text
          html
        }
        client_text {
          text
          html
        }
        date_icon {
          alt
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 50, quality: 85) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
        date_label {
          text
          html
        }
        date_text {
          text
          html
        }
        location_icon {
          alt
          localFile {
            id
            childImageSharp {
              fluid(maxWidth: 50, quality: 85) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
        location_label {
          text
          html
        }
        location_text {
          text
          html
        }
        body {
          ... on Node {
            id
          }
          ... on PrismicUseCaseBodyText {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_text {
                html
              }
            }
          }
          ... on PrismicUseCaseBodyQuote {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_quote {
                text
                html
              }
              slice_quote_name {
                text
                html
              }
              slice_image {
                alt
                fluid(maxWidth: 250) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicUseCaseBodyTextWithImage {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_video {
                url
                link_type
              }
              slice_background_color
              overlapping_image
              image_position
            }
            items {
              item_highlighted_text {
                text
                html
              }
            }
          }
          ... on PrismicUseCaseBodyItemsList {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_image {
                alt
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_color
              overlapping_image
              image_position
            }
            items {
              item_name {
                text
                html
              }
              item_link {
                link_type
                target
                url
              }
              item_icon {
                alt
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 50, quality: 85) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on PrismicUseCaseBodyHighlightedItems {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_icon {
                alt
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 70, quality: 85) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on PrismicUseCaseBodySingleImage {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_image {
                alt
                fluid(maxWidth: 1000) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_caption {
                text
                html
              }
              image_width
            }
          }
          ... on PrismicUseCaseBodyImageGrid {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              image_width
              max_line_items
            }
            items {
              item_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
              item_caption {
                text
                html
              }
            }
          }
          ... on PrismicUseCaseBodyVideo {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_link {
                link_type
                url
              }
              video_width
              autoplay
              caption {
                text
                html
              }
              slice_image_placeholder {
                alt
                fluid(maxWidth: 1000) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicUseCaseBodyHighlightedContent {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
              image_position
            }
            items {
              item_title {
                text
                html
              }
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicUseCaseBodyRelatedContent {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_link_label {
                text
              }
              slice_link {
                link_type
                target
                url
              }
              slice_background_color
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_image {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicUseCaseBodyBanner {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_overlapping_image {
                alt
                fluid(maxWidth: 400) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_image {
                alt
                fluid(maxWidth: 800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_image_color
              slice_background_color
            }
            items {
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_link_style
            }
          }
          ... on PrismicUseCaseBodySolutions {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_link_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_background_image {
                alt
                fluid(maxWidth: 960) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicUseCaseBodyLinksGrid {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
            }
            items {
              item_link_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
            }
          }
          ... on PrismicUseCaseBodyTitleAndDescription {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_title_image {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_color
              container_width
            }
          }
          ... on PrismicUseCaseBodyTabbedCards {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_tab_name {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_color
            }
            items {
              item_name {
                text
                html
              }
              item_link {
                link_type
                target
                url
              }
              item_icon {
                alt
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 50, quality: 85) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on PrismicUseCaseBodyArViewer {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_glb_model_link {
                link_type
                url
              }
              select_usdz_model_link {
                link_type
                url
              }
              slice_background_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_color
              viewer_button
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_icon {
                alt
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 50, quality: 85) {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on PrismicUseCaseBodyPrice {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_price {
                text
              }
              slice_image {
                alt
                fluid(maxWidth: 500) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_image_color
              slice_background_color
              overlapping_image
            }
            items {
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_link_style
            }
          }
          ... on PrismicUseCaseBodyPrices {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_price {
                text
              }
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_image {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicUseCaseBodyEmbeddedContent {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_embedded_content {
                text
                html
              }
            }
          }
          ... on PrismicUseCaseBodyButtonCards {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
            }
            items {
              item_title {
                text
              }
              item_description {
                text
                html
              }
              item_link {
                link_type
                target
                url
              }
              item_image {
                alt
                fluid(maxWidth: 400) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicUseCaseBodyContentGrid {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_image {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicUseCaseBodyCallToAction {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
            }
            items {
              item_link_label {
                text
              }
              item_link {
                link_type
                target
                url
              }
              item_link_variant
            }
          }
          ... on PrismicUseCaseBodyFloatingButton {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_label {
                text
              }
              slice_link {
                link_type
                target
                url
              }
            }
          }
          ... on PrismicUseCaseBodyIconsGrid {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              max_line_items
            }
            items {
              item_icon {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
              item_name {
                text
                html
              }
              item_link {
                link_type
                target
                url
              }
            }
          }
          ... on PrismicUseCaseBodyIconsCarousel {
            id
            slice_type
            primary {
              html_id
              html_class
              html_css {
                text
                html
              }
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
            }
            items {
              item_icon {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
              item_name {
                text
                html
              }
              item_link {
                link_type
                target
                url
              }
            }
          }
        }
      }
    }
  }
`;
