// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  text: {
    marginTop: '0px !important',
    marginBottom: '0px !important',
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      fontWeight: 700,
      '&:hover': {
        textDecoration: 'underline'
      }
    },
    '& h4, & h5, & h6, ': {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
      '& > b, & > strong': {
        color: theme.palette.primary.main,
      },
    },
    '& h4:first-child, & h5:first-child, & h6:first-child, ': {
      marginTop: `0px !important`,
    },
    '& p:not(:last-child)': {
      paddingBottom: theme.spacing(2),
    },
    '& .MuiListText-padding': {
      paddingTop: 16,
      paddingBottom: 16,
    },
    '& li:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
});

export default styles;
