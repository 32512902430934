// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

import NodeImage from '~plugins/prismic/components/NodeImage';
import RichText from '~plugins/prismic/components/RichText';
import type { PrismicImageType, PrismicStructuredTextType } from '~schema';

export type ClassKey =
  | 'root'
  | 'icon'
  | 'textWrapper'
  | 'title'
  | 'description';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  icon?: ?PrismicImageType,
  title: ?string,
  description: ?PrismicStructuredTextType,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
  },
  icon: {
    width: '100%',
    height: '100%',
    minWidth: 40,
    maxWidth: 40,
  },
  textWrapper: {
    marginLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
  title: {
    color: theme.palette.common.black,
  },
  description: {
    '* + &': {
      marginTop: theme.spacing(1),
    },
  },
});

const ItemWithIcon = ({
  icon,
  title,
  description,
  className,
  classes,
  ...props
}: Props): React.Node => {
  return (
    <div className={classnames(classes.root, className)} {...props}>
      <NodeImage data={icon} className={classes.icon} />
      <div className={classes.textWrapper}>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <RichText {...description} className={classes.description} />
      </div>
    </div>
  );
};

ItemWithIcon.defaultProps = {
  className: undefined,
  icon: undefined,
};

export default withStyles<*, *, *>(styles, { name: 'ItemWithIcon' })(ItemWithIcon);
